.cluster-pin-container {
    transform: translate(-50%, -100%); /* Center the pin and point to the location */
  }
  .cluster-pin {
    position: relative; /* Use relative positioning within the container */
    border-radius: 50%;
    border: 18px solid #051A36;
    background: #051A36;
    width: 18px;
    height: 18px;
  }
  .cluster-pin::after {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    bottom: -40px; /* Adjust the pin tail to touch the map location */
    left: 50%;
    transform: translateX(-50%);
    border: 15px solid transparent;
    border-top: 15px solid #051A36;
  }
  .cluster-pin span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    color: #ffffff;
    font-weight: bold;
  }