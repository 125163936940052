.expanded-row {
    animation: expandRow 0.3s ease-in-out;
  }
  
  .unexpanded-row {
    animation: unexpandRow 0.3s ease-in-out;
  }
  
  @keyframes expandRow {
    from {
      opacity: 0;
      transform: scaleY(0);
      transform-origin: top;
    }
    to {
      opacity: 1;
      transform: scaleY(1);
      transform-origin: top;
    }
  }
  
  @keyframes unexpandRow {
    from {
      opacity: 1;
      transform: scaleY(1);
      transform-origin: top;
    }
    to {
      opacity: 0;
      transform: scaleY(0);
      transform-origin: top;
    }
  }