.inspector-panel {
  position: absolute;
  top: 10px;
  right: 10px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  min-width: 300px;
  width: fit-content;
  max-width: 500px;
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  box-sizing: border-box;
  padding-top: 0;
  overflow: hidden;
}

.inspector-header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1001;
  padding: 8px 16px;
  border-bottom: 1px solid #e0e0e0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inspector-header h4 {
  font-size: 16px;
  color: #051A36;
  margin: 0;
  padding: 0;
  border: none;
  white-space: nowrap;
}

.inspector-buttons {
  display: flex;
  gap: 4px;
  margin: 0;
  padding: 0;
  border: none;
}

.inspector-content {
  padding: 16px;
  padding-top: 8px;
  overflow-y: auto;
  max-height: calc(100vh - 150px - 45px);
}

/* Create a container for each label-input pair */
.inspector-panel .field-row {
  display: grid;
  grid-template-columns: minmax(60px, auto) 1fr;
  gap: 8px;
  align-items: center;
  margin-bottom: 8px;
  white-space: nowrap;
}

/* Style for measure and maintenance items */
.measure-item,
.maintenance-item {
  border: 1px solid #ddd;
  padding: 12px;
  margin: 5px 0;
  border-radius: 4px;
}

/* Make inputs take full width of their grid cell */
.inspector-panel input {
  width: 100%;
  box-sizing: border-box;
  padding: 1px 8px;
  margin: 0px;
  user-select: text;
  -webkit-user-select: text;
  cursor: text;
  &::selection {
    background-color: #b4d5fe;
    color: inherit;
  }
  &::-moz-selection {
    background-color: #b4d5fe;
    color: inherit;
  }
}

.inspector-panel .field-row label {
  text-align: left;
  padding-right: 0;
}

/* Style for the details/summary elements */
.inspector-panel details {
  margin: 0;  /* Remove margin */
}

.inspector-panel summary {
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
  padding: 4px 0;  /* Add some vertical padding instead of margins */
  outline: none;  /* Remove default outline */
}

/* Remove default details marker */
.inspector-panel details > summary {
  list-style: none;
}

/* Add custom arrow marker */
.inspector-panel details > summary::before {
  content: "▶";
  display: inline-block;
  margin-right: 4px;
  font-size: 10px;
}

.inspector-panel details[open] > summary::before {
  content: "▼";
}

/* Button styling */
.inspector-panel button {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 2px 8px;  /* Reduced padding */
  margin: 0;  /* Remove margin */
  cursor: pointer;
  font-size: 12px;  /* Slightly smaller font */
  transition: background-color 0.2s;
  line-height: 1.5;  /* Reduced line height */
}

.inspector-panel button:hover {
  background-color: #e0e0e0;
}

.inspector-panel button:active {
  background-color: #d0d0d0;
}

.inspector-panel button.remove-button {
  background-color: #fff0f0;
  border-color: #ffcccb;
  color: #d63031;
}

.inspector-panel button.remove-button:hover {
  background-color: #ffe0e0;
}

.inspector-panel button:disabled {
  background-color: #f5f5f5;
  border-color: #ddd;
  color: #999;
  cursor: not-allowed;
}

/* Specific styling for readings within measures */
.measure-item > div {
  display: grid;
  grid-template-columns: 120px 1fr;
  gap: 8px;
  align-items: center;
  margin: 4px 0;
}

/* Add styles for measure/maintenance headers */
.measure-item h5,
.maintenance-item h5 {
  margin: 0;  /* Remove default margins */
  display: inline-block;  /* Allow button to sit on same line */
}

/* Container for title and button */
.item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.inspector-panel button.save-button {
  background-color: #f0fff0;
  border-color: #90EE90;
  color: #2ecc71;
}

.inspector-panel button.save-button:hover {
  background-color: #e0ffe0;
}

.inspector-panel button.save-button:disabled {
  background-color: #f5f5f5;
  border-color: #ddd;
  color: #999;
  cursor: not-allowed;
}

.inspector-panel .read-only-value {
  padding: 1px 8px;
  color: #666;
  user-select: text;
  -webkit-user-select: text;
  cursor: text;
  &::selection {
    background-color: #b4d5fe;
    color: inherit;
  }
  &::-moz-selection {
    background-color: #b4d5fe;
    color: inherit;
  }
}

.inspector-content > details > summary {
  margin-top: 8px; /* Add margin to the first summary element */
}

/* Or if you prefer more space: */
.inspector-content > details {
  margin-top: 12px; /* Add margin to the details container */
}

/* Add these new styles */
.reading-row {
  margin-bottom: 4px !important;
  display: block !important; /* Override the grid from field-row */
}

.reading-inputs {
  display: grid;
  grid-template-columns: 1fr 1fr 80px;
  gap: 4px;
  width: 100%;
}

.reading-inputs input,
.reading-inputs select {
  width: 100%;
  padding: 1px 8px;
  box-sizing: border-box;
}

.reading-inputs select {
  padding: 0px 4px;
}

/* Optional: Add a subtle background to the readings section */
.measure-item .reading-row {
  background-color: #f9f9f9;
  padding: 4px;
  border-radius: 4px;
}

/* Add new style for the date-score-row */
.date-score-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
}

.date-score-row .field-group {
  display: flex;
  gap: 2px;
  align-items: center;
}

.date-score-row label {
  white-space: nowrap;
  font-size: 12px;
}

/* Add specific sizing for date and score inputs */
.date-score-row .field-group:first-child input {
  width: 110px;  /* For date field */
}

.date-score-row .field-group:last-child {
  margin-right: auto;  /* Push the score group to align with content below */
  margin-left: 32px;   /* Add some space between date and score */
}

.date-score-row .field-group:last-child input {
  width: 50px;  /* For score field */
}