#controls {
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.markerControls {
  display: flex;
  gap: 10px;
}

.saveControls {
  display: flex;
  gap: 5px;
}

.markerButton {
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.markerButton.active {
  background-color: #007bff;
  color: white;
  border-color: #0056b3;
}

.saveButton {
  padding: 8px 16px;
  cursor: pointer;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  min-width: 120px;
  text-align: center;
}

.saveButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.saveButton:hover:not(:disabled) {
  background-color: #45a049;
}

.markerTypeGroup {
  display: flex;
  gap: 10px;
}

.jsonButtonGroup {
  display: flex;
  gap: 10px;
  margin-left: auto;
} 