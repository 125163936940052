.container {
    background-color: #fff;
    width: 100%;
    padding: 12px 25px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  @media (max-width: 991px) {
    .container {
      max-width: 100%;
      padding: 0 20px;
    }
  }
  
  .title {
    color: #000;
    align-self: stretch;
    flex-grow: 1;
    margin: auto 0;
    font: 700 25px/1.2 Steradian, -apple-system, Roboto, Helvetica, sans-serif;
  }
  
  .infoItem {
    display: flex;
    gap: 8px;
    color: #000;
    white-space: nowrap;
    font: 400 16px/1.2 Steradian, -apple-system, Roboto, Helvetica, sans-serif;
    justify-content: flex-start;
  }
  
  @media (max-width: 991px) {
    .infoItem {
      white-space: initial;
    }
  }
  
  .icon {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 32px;
  }
  
  .infoText {
    margin: auto 0;
  }
  
  .statusItem {
    align-self: stretch;
    display: flex;
    gap: 4px;
    color: #1d1d1b;
    white-space: nowrap;
    margin: auto 0;
    font: 400 14px/1.2 Steradian, -apple-system, Roboto, Helvetica, sans-serif;
  }
  
  @media (max-width: 991px) {
    .statusItem {
      white-space: initial;
    }
  }
  
  .statusIndicator {
    border-radius: 50%;
    display: flex;
    width: 8px;
    height: 8px;
    margin: auto 0;
  }
  
  .redIndicator {
    background-color: #fd5050;
  }
  
  .yellowIndicator {
    background-color: #ffc93f;
  }
  
  .greenIndicator {
    background-color: #68fd50;
  }
  
  .actionIcons {
    display: flex;
    gap: 24px;
  }
  
  .statusGroup {
    position: relative;
    display: flex;
    gap: 10px;
    padding: 8px 16px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .graphTooltip {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    padding: 16px;
    margin-top: 18px;
    z-index: 1000;
    width: 400px;
  }
  
  /* Add a small triangle pointer */
  .graphTooltip::before {
    content: '';
    position: absolute;
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid white;
  }
  
  .container {
    padding: 20px;
  }
  
  .gridLayout {
    display: grid;
    grid-template-columns: auto repeat(5, 1fr) auto;
    align-items: center;
    gap: 24px;
    width: 100%;
    padding: 16px;
  }
  
  .title {
    margin: 0;
    justify-self: start;
  }
  
  .infoItem, .statusGroup {
    justify-self: center;
  }
  
  .actionIcons {
    display: flex;
    gap: 16px;
    justify-self: end;
  }
  
  @media (max-width: 991px) {
    .gridLayout {
      grid-template-columns: auto;
      gap: 16px;
    }
  }