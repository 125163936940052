.image-preview {
  width: 100px;
  height: 100px;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
}

.image-preview img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.placeholder-text {
  color: #666;
  text-align: center;
  font-size: 0.9em;
}

.image-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1002;
}

.image-modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80vw;
  max-height: 80vh;
  overflow-y: auto;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
  padding: 10px;
}

.image-grid img {
  width: 100%;
  height: 150px;
  object-fit: contain;
  cursor: pointer;
  border: 2px solid transparent;
  padding: 5px;
}

.image-grid img.selected {
  border-color: #007bff;
}

.image-grid img:hover {
  background-color: #f0f0f0;
} 