* {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.markerButton {
    padding: 10px;
    font-size: 14px;
    margin-right: 5px;
    border: 2px solid #ccc;
    background-color: white;
    cursor: pointer;
}

.markerButton.active {
    background-color: #051A36;
    color: white;
    border-color: #051A36;
}

.custom-marker {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #ff0000;
    cursor: pointer;
    outline: none !important;
    transform: translateY(12px);
    -webkit-tap-highlight-color: transparent;
}

.custom-marker.selected {
    background-color: #0066ff;
    border: 2px solid white;
}

.custom-marker-bubble {
    background: #051A36;
    border-radius: 10px;
    padding: 5px;
    text-align: center;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    color: white;
    display: inline-block;
    width: auto;
}

.custom-marker-image {
    width: 110px;
    height: auto;
    border-radius: 5px;
    box-sizing: border-box;
    display: block;
    margin: 0 auto;
}

.custom-marker-info {
    margin-top: 5px;
}

.dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
}

.dot.red { background-color: red; }
.dot.yellow { background-color: yellow; }
.dot.green { background-color: green; }

.custom-marker-triangle {
    position: relative;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #051A36;
    margin: 0 auto;
    transform: translateY(-1px);
}

.inspector-panel {
    position: absolute;
    top: 10px;
    right: 10px;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    width: 280px;
}

/* Title section */
.inspector-panel h4 {
    font-size: 16px;
    color: #051A36;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e0e0e0;
}

/* Properties section */
.inspector-panel label {
    display: inline-block;
    width: 60px;
    color: #666;
    font-size: 14px;
}

.inspector-panel input {
    width: calc(100% - 65px);
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    margin: 0 0 15px 0;
    transition: border-color 0.2s;
}

.inspector-panel input:focus {
    outline: none;
    border-color: #0066ff;
    box-shadow: 0 0 0 2px rgba(0, 102, 255, 0.1);
}

.inspector-panel input::placeholder {
    color: #999;
    font-style: italic;
}

/* Buttons section */
.inspector-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #e0e0e0;
}

.inspector-buttons button {
    padding: 8px 16px;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.2s;
    border: none;
}

.inspector-buttons button:first-child {
    background-color: #0066ff;
    color: white;
}

.inspector-buttons button:first-child:hover {
    background-color: #0052cc;
}

.inspector-buttons button:first-child:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.inspector-buttons .remove-button {
    background-color: #fff;
    color: #dc3545;
    border: 1px solid #dc3545;
}

.inspector-buttons .remove-button:hover {
    background-color: #dc3545;
    color: white;
}

#saveButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

#removeButton {
    background-color: #ff4444;
    color: white;
}

.selection-box {
    position: absolute;
    border: 2px solid #0066ff;
    background-color: rgba(0, 102, 255, 0.1);
    pointer-events: none;
    display: none;
}

/* Disable text/element selection on the map container */
.map-container, 
.map-container * {
  user-select: none !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
}

/* Disable default Google Maps highlighting */
.gm-style *::selection { 
  background-color: transparent;
}

.gm-style *::-moz-selection { 
  background-color: transparent;
}

.gm-style div {
    outline: none !important;
}

.gm-style img {
    outline: none !important;
}

::selection {
    background: transparent;
}

::-moz-selection {
    background: transparent;
}

.map-container {
    height: calc(100vh - 120px);
    width: 100%;
    position: relative;
}

#controls {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    padding: 10px;
}

.saveButton {
    padding: 10px 20px;
    font-size: 14px;
    background-color: #0066ff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: auto;
}

.saveButton:hover {
    background-color: #0052cc;
}

.saveButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}